@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 100;
    src: url("./assets/fonts/TomatoGrotesk-Thin.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 200;
    src: url("./assets/fonts/TomatoGrotesk-ExtraLight.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 300;
    src: url("./assets/fonts/TomatoGrotesk-Light.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 400;
    src: url("./assets/fonts/TomatoGrotesk-Regular.otf");
    font-display: swap;
  }
  @font-face {
    -family: "Tomato Grotesk";
    font-weight: 500;
    src: url("./assets/fonts/TomatoGrotesk-Medium.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 600;
    src: url("./assets/fonts/TomatoGrotesk-SemiBold.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 700;
    src: url("./assets/fonts/TomatoGrotesk-Bold.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 800;
    src: url("./assets/fonts/TomatoGrotesk-ExtraBold.otf");
    font-display: swap;
  }
  @font-face {
    font-family: "Tomato Grotesk";
    font-weight: 900;
    src: url("./assets/fonts/TomatoGrotesk-Black.otf");
    font-display: swap;
  }

  body {
    font-family: "Tomato Grotesk";
    -webkit-tap-highlight-color: transparent;
  }
  .sidebar-menu-item:hover svg {
    stroke: theme("colors.white") !important;
    fill: theme("colors.white") !important;
  }
}

.react-select__control {
  @apply h-10 xl:h-[50px];
}

.react-select-container {
  margin-top: 0.25rem;
}

/* width */
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply rounded-lg bg-green-450;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-green-600;
}

.react-select__control--is-focused {
  @apply bg-green-600;
}
